import React, { Component } from 'react'
import { connect } from 'react-redux'

import DataProvider from './DataProvider'
import Dashboard from './Dashboard'
import { loadSources, loadTopics } from './asyncAction'


const PATH = ['sourcesAndTopics']
const SOURCES = [ loadSources, loadTopics ]

class DashboardRoot extends Component {
	render() {
		return (
			<div className="DashboardRoot" key="DashboardRoot">
				<DataProvider
					dataKey={"immutable"}
					path={PATH}
					sources={SOURCES}
					component={Dashboard}
				/>
			</div>
		)
	}
}


const mapStateToProps = (state, ownProps) => ({})
const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DashboardRoot)
