import React, { Component } from 'react'

import DashboardRoot from './DashboardRoot'
import total from './total-logo.png'

import { API_PREFIX } from './config'


class App extends Component {
	constructor() {
		super();

		this.handleUsernameChange = this.handleUsernameChange.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			mode: 'empty',
			username: '',
			password: '',
		}
	}

	handleUsernameChange(event) {
		this.setState({username: event.target.value});
	}

	handlePasswordChange(event) {
		this.setState({password: event.target.value});
	}

	handleSubmit(event) {
		event.preventDefault();

		let self = this;

		fetch(`${API_PREFIX}/auth`, {
			method: 'POST',
			body: JSON.stringify({
				username: self.state.username,
				password: self.state.password,
			}),
			mode: 'cors',
			credentials: 'include',
		})
		.then(function(response){
			if (response.ok) {
				self.setState({ mode: 'dashboard' });
			} else {
				self.setState({ mode: 'error' });
			}
		})
		.catch(function(err){
			self.setState({ mode: 'error' });
		});
	}

	componentDidMount() {
		let self = this;

		fetch(`${API_PREFIX}/auth/check`, {
			mode: 'cors',
			credentials: 'include',
		})
		.then(function(response){
			if (response.ok) {
				self.setState({ mode: 'dashboard' });
			} else {
				self.setState({ mode: 'login-form' });
			}
		})
		.catch(function(err){
			self.setState({ mode: 'error' });
		});
	}

	render() {
		if (this.state.mode === 'error') {
			return (
				<div className='App'>
					<div className="row">
						<div className="col-md-12">
							<p className="text-center text-danger">Ошибка при загрузке данных</p>
						</div>
					</div>
				</div>
			)
		}

		if (this.state.mode === 'empty') {
			return (
				<div className='App'>
					<div className="row">
						<div className="col-md-12">
							<p className="text-center">Загрузка...</p>
						</div>
					</div>
				</div>
			)
		}

		if (this.state.mode === 'login-form') {
			return (
				<div className='App'>
					<div className="container">
					<div className="row">
						<div className="col-md-4 col-md-offset-4">
							<hr />
							<div style={{textAlign: 'center'}}>
								<img src={total} alt="Total logo" width='120px' />
							</div>
							<hr />
							<form onSubmit={this.handleSubmit}>
								<div className="form-group">
									<label htmlFor="exampleInputEmail1">Логин</label>
									<input onChange={this.handleUsernameChange} className="form-control" />
								</div>
								<div className="form-group">
									<label htmlFor="exampleInputPassword1">Пароль</label>
									<input onChange={this.handlePasswordChange} type='password' className="form-control" />
								</div>
								<div style={{textAlign: 'center'}}>
									<button type="submit" className="btn btn-default btn-lg">Войти</button>
								</div>
								{/* <p>{this.state.username} || {this.state.password}</p> */}
							</form>
						</div>
					</div>
				</div>
				</div>
			)
		}

		return (
			<div className='App'>
				<DashboardRoot />
			</div>
		)
	}
}

export default App;
