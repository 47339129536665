import React, { Component } from 'react'
import { connect } from 'react-redux'

import { test, loadStarted, loadSuccess, loadFailed } from './action'

import './DataProvider.css'

/*
Usage example:

const PATH = ['shipping', 'bubblechart', 'data']
const SOURCES = [ loadBubblechartData ] // thunk, async action, returns promise
...
<DataProvider
	dataKey={something_that_changes_when_we_need_to_reload}
	path={PATH}
	sources={SOURCES}
	component={ShippingBubblechart}
/>

Component will receive `data` prop (and all the others).
*/

class DataProvider extends Component {
	constructor() {
		super()
		this.fetchData = this.fetchData.bind(this)
	}

	fetchData() {
		this.props.onStart()
		this.props.dispatchThunk((dispatch, getState) => {
			let promises = []
			for (let fn of this.props.sources) {
				promises.push(fn(dispatch, getState))
			}

			// TODO: discard data (and errors) if dataKey has expired

			Promise.all(promises).then((data) => {
				this.props.onSuccess(data)
			}).catch((error) => {
				this.props.onError(error)
			})
		})
	}

	componentDidMount() {
		if (this.props.lastDataKey !== this.props.dataKey) {
			console.log(`DataProvider at /${this.props.path.join('/')}: data keys differ, re-fetch (in componentDidMount)`)
			this.fetchData()
		}
	}

	componentDidUpdate() {
		// console.log(`DataProvider at /${this.props.path}: componentDidUpdate`)
		if (this.props.lastDataKey !== this.props.dataKey) {
			console.log(`DataProvider at /${this.props.path.join('/')}: data keys differ, re-fetch`)
			this.fetchData()
		}
	}

	render() {
		let Target = this.props.component

		let upperPart = null
		let lowerPart = null

		if (!!this.props.data) {
			lowerPart = (
				<div className="box normal"><Target {...this.props} /></div>
			)

			if (this.props.error) upperPart = (
				<div style={{ textAlign: 'center' }}>При загрузке данных произошла ошибка</div>
			)

			if (this.props.loading) {
				lowerPart = (
					<div className="box loading"><Target {...this.props} /></div>
				)
			}
		} else {
			if (this.props.loading) {
				upperPart = (
					<div style={{ textAlign: 'center' }}>Загрузка...</div>
				)
			}

			if (this.props.error) upperPart = (
				<div style={{ textAlign: 'center' }}>При загрузке данных произошла ошибка</div>
			)
		}

		return (
			<div className="DataProvider">
				{upperPart}
				{lowerPart}
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		loading: state.getIn(ownProps.path.concat(['loading'])),
		error: state.getIn(ownProps.path.concat(['error'])),
		data: state.getIn(ownProps.path.concat(['data'])),
		lastDataKey: state.getIn(ownProps.path.concat(['lastDataKey'])),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onStart: () => {
			dispatch(loadStarted(ownProps.path, ownProps.dataKey))
		},
		onSuccess: (data) => {
			dispatch(loadSuccess(ownProps.path, data))
		},
		onError: (error) => {
			dispatch(loadFailed(ownProps.path, error))
		},

		test: () => {
			dispatch(test())
		},

		dispatchThunk: (thunk) => {
			dispatch(thunk)
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DataProvider)
