import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'; import 'moment/locale/ru.js'
import Datetime from 'react-datetime'; import 'react-datetime/css/react-datetime.css'

import { filterDateFromChanged, filterDateToChanged, detailFilterTopicIDChanged, detailFilterSourceChanged, dynamicFilterTopicIDChanged } from './action'
import { getChartOptions } from './proxy-chartjs'

import TopicList from './TopicList.js'
import CategoryGraph from './CategoryGraph.js'
import SourceGraph from './SourceGraph.js'
import MonthlyGraph from './MonthlyGraph.js'
import SubcategoryGraph from './SubcategoryGraph.js'
import CarGraph from './CarGraph.js'
import DynGraph from './DynGraph.js'
import TotalCount from './TotalCount.js'
import TeamTransferCount from './TeamTransferCount.js'

import bg from './header-bg.png'
import comsys from './comsystems-logo.png'
import total from './total-logo.png'

import './Dashboard.css'


const TOPIC_REANNOTATIONS = {
	'Технический отдел': 'ТО',
	'Логистический отдел': 'ЛО',
	'Коммерческий отдел': 'КО',
	'Другие вопросы': 'Другое',
}

const makeMap = (x) => {
	let ret = {};
	for (let i in x) {
		if (x.hasOwnProperty(i)) {
			ret[''+x[i].id+''] = x[i].name;
		}
	}
	return ret;
}

const getSourceOptions = (sources, onChange) => {
	let childs = [];
	childs.push(<option value="">По всем каналам</option>);
	for (let i in sources) { if (sources.hasOwnProperty(i)) {
		// TODO: make this blacklist a constant
		if (i === 'API' || i === 'Other') continue; // they want me to hide these channels
		let src = sources[i];
		childs.push(
			<option value={i}>{src}</option>
		);
	}}
	return (
		React.createElement('select', {onChange: onChange}, ...childs)
	);
}

class Dashboard extends Component {
	// constructor() {
	// 	super()
	// 	// this.fetchData = this.fetchData.bind(this)
	// }

	render() {
		let sources = this.props.data.toJS()[0]
		let topics = this.props.data.toJS()[1]
		let topicsAnnotations = makeMap(this.props.data.toJS()[1])

		return (
			<div className="Dashboard">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<table className="table">
								<tbody>
								<tr>
									<td><img src={total} height="120" alt="Total logo" /></td>
									<td className="text-center header" style={{ backgroundImage: 'url('+bg+')' }}>
										<h3 className="smcp header-text line1"><b>Статистика по проекту</b></h3>
										<h3 className="smcp header-text line2"><b>&laquo;Консультация потребителей моторных масел Total&raquo;</b></h3>
									</td>
									<td><img src={comsys} height="120" alt="Comsystems logo" /></td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="text-center">
								<h1 className="smcp">Общие результаты</h1>
								<hr />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<h3 className="smcp">По категориям</h3>
							<CategoryGraph
								from={this.props.dateFrom}
								to={this.props.dateTo}
								chartOptions={getChartOptions('percent')}
								height={350}
								annotate={topicsAnnotations}
								reannotate={TOPIC_REANNOTATIONS}
							/>
						</div>
						<div className="col-md-4">
							<table style={{width: '100%'}}>
								<tbody>
								<tr>
									<td>с:&nbsp;</td>
									<td>
										<Datetime
											locale="ru"
											dateFormat="YYYY-MM"
											defaultValue={this.props.dateFrom}
											onChange={this.props.onDateFromChanged}
											closeOnSelect={true}
										/>
									</td>
								</tr>
								<tr>
									<td>&nbsp;</td>
									<td>&nbsp;</td>
								</tr>
								<tr>
									<td>по:&nbsp;</td>
									<td>
										<Datetime
											locale="ru"
											dateFormat="YYYY-MM"
											defaultValue={moment(this.props.dateTo).subtract(1, 'month')}
											onChange={this.props.onDateToChanged}
											closeOnSelect={true}
										/>
									</td>
								</tr>
								</tbody>
							</table>
							<hr />
							<div className="text-center">
								<TotalCount
									from={this.props.dateFrom}
									to={this.props.dateTo}
									textClass="allcaps"
								/>
								<hr />
								<table><tbody><tr>
									<td>
										<TeamTransferCount
											from={this.props.dateFrom}
											to={this.props.dateTo}
											team_name="total-dashboard-line2"
											path={['countSecondLine']}
											text="Переведено на&nbsp;вторую линию"
										/>
									</td>
									<td>
										<TeamTransferCount
											from={this.props.dateFrom}
											to={this.props.dateTo}
											team_name="Customer Service"
											path={['countCustomerService']}
											text="Переведено на&nbsp;Customer Service"
										/>
									</td>
								</tr></tbody></table>
							</div>
						</div>
						<div className="col-md-4">
							<h3 className="smcp">По каналам</h3>
							<SourceGraph
								from={this.props.dateFrom}
								to={this.props.dateTo}
								chartOptions={getChartOptions('percent')}
								annotate={sources}
								height={350}
							/>
						</div>
					</div>
					<hr />
					<div className="details">
						<div className="row">
							<div className="col-md-12">
								<div className="pull-left">
									<TopicList
										topics={topics}
										onChange={this.props.onTopicChanged}
										includeChildren={false}
									/>
								</div>
								<div className="pull-right">
									{getSourceOptions(sources, this.props.onSourceChanged)}
								</div>
								<h1 className="text-center smcp">Детализация</h1>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 framed">
								<h3 className="smcp">Динамика по месяцам</h3>
								<MonthlyGraph
									from={this.props.dateFrom}
									to={this.props.dateTo}
									topic={this.props.detailTopicID}
									source={this.props.detailSource}
									chartOptions={getChartOptions('value')}
									height={300}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6 framed ds">
								<h3 className="smcp">По темам обращений</h3>
								<SubcategoryGraph
									from={this.props.dateFrom}
									to={this.props.dateTo}
									topic={this.props.detailTopicID}
									source={this.props.detailSource}
									chartOptions={getChartOptions('value', null, 'horizontal')}
									annotate={topicsAnnotations}
									height={300}
								/>
							</div>
							<div className="col-md-6 framed">
								<h3 className="smcp">По маркам автомобилей</h3>
								<CarGraph
									from={this.props.dateFrom}
									to={this.props.dateTo}
									topic={this.props.detailTopicID}
									source={this.props.detailSource}
									chartOptions={getChartOptions('value')}
									height={300}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="text-center">
								<h2 className="smcp">Динамика по теме</h2>
								<TopicList
									topics={topics}
									onChange={this.props.onDynamicFilterTopicIDChanged}
									includeChildren={true}
								/>
							</div>
							<DynGraph
								from={this.props.dateFrom}
								to={this.props.dateTo}
								topic={this.props.dynamicTopicID}
								chartOptions={getChartOptions('value')}
								height={400}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<hr />
							<p className="text-center">&copy; &laquo;Комсистемс&raquo;, 2016...2018</p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		dateFrom: state.getIn(['filterDate', 'from']),
		dateTo: state.getIn(['filterDate', 'to']),

		detailTopicID: state.getIn(['detail', 'filterTopicID']),
		detailSource: state.getIn(['detail', 'filterSource']),

		dynamicTopicID: state.getIn(['dynamic', 'filterTopicID']),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onDateFromChanged: (value) => {
			if (!value.toDate) return // ignore invalid dates
			dispatch(filterDateFromChanged(value.toDate()))
		},
		onDateToChanged: (value) => {
			if (!value.toDate) return // ignore invalid dates
			dispatch(filterDateToChanged(value.clone().add(1, 'month').toDate()))
		},

		onTopicChanged: (e) => {
			dispatch(detailFilterTopicIDChanged(+e.target.value))
		},
		onSourceChanged: (e) => {
			dispatch(detailFilterSourceChanged(e.target.value))
		},

		onDynamicFilterTopicIDChanged: (e) => {
			dispatch(dynamicFilterTopicIDChanged(+e.target.value))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
