import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2'

import DataProvider from './DataProvider'
import { loadDynamic } from './asyncAction'
import { formBarData } from './proxy-chartjs.js'


class DynGraph extends Component {
	render() {
		let rawData = this.props.data.toJS()[0]
		let data = formBarData(rawData, {
			mainColor: "91, 155, 213",
			from: this.props.dateFrom,
			to: this.props.dateTo,
		})

		return (
			<div className="DynGraph">
				<Bar
					data={data}
					options={this.props.chartOptions}
					height={this.props.height}
				/>
			</div>
		);
	}
}


const PATH = ['dynamic', 'graph']
const SOURCES = [ loadDynamic ]

class DynGraphRoot extends Component {
	render() {
		return (
			<div className="DynGraphRoot" key="DynGraphRoot">
				<DataProvider
					dataKey={`${this.props.dateFrom}...${this.props.dateTo} ${this.props.dynamicTopicID}`}
					path={PATH}
					sources={SOURCES}
					component={DynGraph}
					{...this.props}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => ({
	dateFrom: state.getIn(['filterDate', 'from']),
	dateTo: state.getIn(['filterDate', 'to']),

	// detailTopicID: state.getIn(['detail', 'filterTopicID']),
	// detailSource: state.getIn(['detail', 'filterSource']),

	dynamicTopicID: state.getIn(['dynamic', 'filterTopicID']),
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DynGraphRoot)
