import React, { Component } from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';

const withChildren = (id, topics, prefix='') => {
	let ret = []
	// root
	for (let topic of topics) {
		if (topic.id === id) {
			ret.push( <option value={topic.id}>{prefix} {topic.name}</option> );
		}
	}
	// leafs
	for (let topic of topics) {
		if (topic.parent_id === id) {
			let l = withChildren(topic.id, topics, prefix+'—')
			for (let x of l) ret.push(x)
			// ret.push( <option value={topic.id}>{topic.name}</option> );
		}
	}
	return ret
}

class TopicList extends Component {
	constructor(props) {
		super(props);
		this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
	}

	render() {
		let childs = [];
		childs.push( <option value={null}>По всем категориям</option> );

		let topics = this.props.topics;
		let includeChildren = this.props.includeChildren;
		let byParent = function(targetID) {
			return function(x) {
				return x.parent_id === targetID;
			};
		};

		topics
		.filter(byParent(0))
		.forEach(function(rt) {
			if (includeChildren) {
				let l = withChildren(rt.id, topics)
				for (let x of l) childs.push(x)
			} else {
				childs.push( <option value={rt.id}>{rt.name}</option> )
			}
		});

		return (
			React.createElement('select', {onChange: this.props.onChange}, ...childs)
		);
	}
}

export default TopicList;
