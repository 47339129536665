import React, { Component } from 'react'
import { connect } from 'react-redux'

import DataProvider from './DataProvider'
import { loadTotalCount } from './asyncAction'
import { plural } from './utils'

import './TotalCount.css'


class TotalCount extends Component {
	render() {
		let rawData = this.props.data.toJS()[0]
		let data = rawData

		return (
			<div className="TotalCount">
				<h1>{data.count}</h1>
				<p className={this.props.textClass}>{plural(data.count, 'заявка', 'заявки', 'заявок')} за этот период</p>
			</div>
		)
	}
}


const PATH = ['countTotal']
const SOURCES = [ loadTotalCount ]

class TotalCountRoot extends Component {
	render() {
		return (
			<div className="TotalCountRoot" key="TotalCountRoot">
				<DataProvider
					dataKey={`${this.props.dateFrom}...${this.props.dateTo}`}
					path={PATH}
					sources={SOURCES}
					component={TotalCount}
					{...this.props}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => ({
	dateFrom: state.getIn(['filterDate', 'from']),
	dateTo: state.getIn(['filterDate', 'to']),
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TotalCountRoot)
