import React, { Component } from 'react'
import { connect } from 'react-redux'

import DataProvider from './DataProvider'
import { loadTeamTransferCount } from './asyncAction'
// import { plural } from './utils'

import './TeamTransferCount.css'


class TeamTransferCount extends Component {
	render() {
		let rawData = this.props.data.toJS()[0]
		let data = rawData

		return (
			<div className="TeamTransferCount">
				<h2>{data.count}</h2>
				{/* <p className={this.props.textClass}>{plural(this.state.count, 'заявка', 'заявки', 'заявок')} за этот период</p> */}
				<p className={this.props.textClass}>{this.props.text}</p>
			</div>
		)
	}
}


class TeamTransferCountRoot extends Component {
	componentWillMount() {
		// funny, props are not available in constructor
		this.sources = [ loadTeamTransferCount(this.props.team_name) ]
	}

	render() {
		return (
			<div className="TeamTransferCountRoot" key="TeamTransferCountRoot">
				<DataProvider
					dataKey={`${this.props.dateFrom}...${this.props.dateTo}`}
					path={this.props.path}
					sources={this.sources}
					component={TeamTransferCount}
					{...this.props}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => ({
	dateFrom: state.getIn(['filterDate', 'from']),
	dateTo: state.getIn(['filterDate', 'to']),
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TeamTransferCountRoot)
