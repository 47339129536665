import moment from 'moment'


export function plural(number, one, two, five) {
	number = Math.abs(number);
	number %= 100;
	if (number >= 5 && number <= 20) {
		return five;
	}
	number %= 10;
	if (number === 1) {
		return one;
	}
	if (number >= 2 && number <= 4) {
		return two;
	}
	return five;
}


export function monthMarks(a, b) {
	a = moment(a);
	b = moment(b);
	let ret = [];
	let guard = 0;
	while(a < b){
		ret.push(a.format('YYYY-MM'));
		a = a.add(1, 'month');

		guard++;
		if (guard > 1000) {
			console.log('monthMarks: bloody years passed, exiting');
			return ret;
		}
	}
	return ret;
}


export function dayMarks(a, b) {
	a = moment(a)
	b = moment(b)
	let ret = []
	let guard = 0
	while (a < b) {
		ret.push(a.format('YYYY-MM-DD'))
		a = a.add(1, 'day')

		guard++
		if (guard > 1000) {
			console.log('dayMarks: bloody years passed, exiting')
			return ret
		}
	}
	return ret
}


export function withinOneMonth(from, to) {
	return moment(to).clone().subtract(1, 'day').diff(moment(from), 'month') === 0
}
