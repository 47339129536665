import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2';

import DataProvider from './DataProvider'
import { loadByCategory } from './asyncAction'
import { formBarData } from './proxy-chartjs.js';


class CategoryGraph extends Component {
	render() {
		let rawData = this.props.data.toJS()[0]
		let data = formBarData(rawData, {
			annotate: this.props.annotate,
			reannotate: this.props.reannotate,
			splitLines: true,
		})

		return (
			<div className="CategoryGraph">
				<Bar
					data={data}
					options={this.props.chartOptions}
					height={this.props.height}
				/>
			</div>
		);
	}
}


const PATH = ['byCategory']
const SOURCES = [ loadByCategory ]

class CategoryGraphRoot extends Component {
	render() {
		return (
			<div className="CategoryGraphRoot" key="CategoryGraphRoot">
				<DataProvider
					dataKey={`${this.props.dateFrom}...${this.props.dateTo}`}
					path={PATH}
					sources={SOURCES}
					component={CategoryGraph}
					{...this.props}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => ({
	dateFrom: state.getIn(['filterDate', 'from']),
	dateTo: state.getIn(['filterDate', 'to']),

	detailTopicID: state.getIn(['detail', 'filterTopicID']),
	detailSource: state.getIn(['detail', 'filterSource']),

	dynamicTopicID: state.getIn(['dynamic', 'filterTopicID']),
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CategoryGraphRoot)
