import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'

import moment from 'moment'

import * as action from './action'

// const dataContainer = () => ({
// 	loading: false,
// 	error: null,
// 	data: null,
// 	lastDataKey: null,
// })

const defaultState = fromJS({
	sourcesAndTopics: {/* managed by DataProvider */},

	filterDate: {
		from: moment.utc('2016-05-01T00:00:00Z').toDate(),
		to: moment.utc().startOf('month').add(1, 'month').toDate(),
	},
	byCategory: {/* managed by DataProvider */},
	countTotal: {/* managed by DataProvider */},
	countSecondLine: {/* managed by DataProvider */},
	countCustomerService: {/* managed by DataProvider */},
	bySource: {/* managed by DataProvider */},

	detail: {
		filterTopicID: null,
		filterSource: null,

		graph: {/* managed by DataProvider */},
		byTopic: {/* managed by DataProvider */},
		byCar: {/* managed by DataProvider */},
	},

	dynamic: {
		filterTopicID: null,
		graph: {/* managed by DataProvider */},
	},
})


export const rootReducer = handleActions({
	// START DATAPROVIDER ACTIONS
	[action.loadStarted](state, action) { return state.withMutations((state) => {
		let key = action.payload.key
		let dataKey = action.payload.dataKey

		state.setIn(key.concat(['loading']), true)
		state.setIn(key.concat(['error']), null)
		state.setIn(key.concat(['lastDataKey']), dataKey)
	})},
	[action.loadSuccess](state, action) { return state.withMutations((state) => {
		let key = action.payload.key
		let data = fromJS(action.payload.data)

		state.setIn(key.concat(['loading']), false)
		state.setIn(key.concat(['data']), data)
	})},
	[action.loadFailed](state, action) { return state.withMutations((state) => {
		let key = action.payload.key
		let error = action.payload.error

		state.setIn(key.concat(['loading']), false)
		state.setIn(key.concat(['error']), error)
	})},
	// END DATAPROVIDER ACTIONS

	[action.unsafeDirectSet](state, action) {
		return state.withMutations((state) => {
			let key = action.payload.key
			let value = action.payload.value

			if (typeof(value) === 'object') {
				console.log("Setting a non-primitive (potentially mutable) value in immutable tree. Looks like a mistake, ignoring.")
				return
			}

			state.setIn(key, value)
		})
	},

	[action.filterDateFromChanged](state, action) { return state.withMutations((state) => {
		let { date } = action.payload
		state.setIn(['filterDate', 'from'], date)
	})},
	[action.filterDateToChanged](state, action) { return state.withMutations((state) => {
		let { date } = action.payload
		state.setIn(['filterDate', 'to'], date)
	})},

	[action.detailFilterTopicIDChanged](state, action) { return state.withMutations((state) => {
		let { id } = action.payload
		state.setIn(['detail', 'filterTopicID'], id)
	})},
	[action.detailFilterSourceChanged](state, action) { return state.withMutations((state) => {
		let { value } = action.payload
		state.setIn(['detail', 'filterSource'], value)
	})},

	[action.dynamicFilterTopicIDChanged](state, action) { return state.withMutations((state) => {
		let { id } = action.payload
		state.setIn(['dynamic', 'filterTopicID'], id)
	})},
}, defaultState)
