import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import { Iterable } from 'immutable'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import App from './App'
import { rootReducer } from './reducer'

// import './index.css'


if (process.env.NODE_ENV !== 'production') document.title = '[DEV] '+document.title

// const stateTransformer = (state) => { if (Iterable.isIterable(state)) return state.toJS(); else return state }
const stateTransformer = (state) => (Iterable.isIterable(state) ? state.toJS() : state)

const logger = createLogger({
	stateTransformer,
	collapsed: true,
	diff: true,
})

let store = createStore(
	rootReducer,
	applyMiddleware(logger),
	applyMiddleware(thunk),
)

ReactDOM.render((
	<Provider store={store}>
		<App />
	</Provider>
), document.getElementById('root'))
