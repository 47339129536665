import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Chart } from 'react-google-charts'

// import C3Chart from 'react-c3js'
import C3Chart from './custom-c3-binding'
import 'c3/c3.css'

import DataProvider from './DataProvider'
import { loadByMonthOrByDay } from './asyncAction'
// import { formLineData as fld } from './proxy-google.js'
import { formLineData } from './proxy-c3'
import { withinOneMonth, dayMarks, monthMarks } from './utils'

import './MonthlyGraph.css'


class MonthlyGraph extends Component {
	render() {
		let rawData = this.props.data.toJS()[0]

		let opts = {
			name: 'Обращений',
		}
		if (withinOneMonth(this.props.dateFrom, this.props.dateTo)) {
			opts.timeseries = '%Y-%m-%d'
			for (let i of dayMarks(this.props.dateFrom, this.props.dateTo)) rawData[i] |= 0
		} else {
			opts.from = this.props.dateFrom
			opts.to = this.props.dateTo
			opts.timeseries = '%Y-%m'
			for (let i of monthMarks(this.props.dateFrom, this.props.dateTo)) rawData[i] |= 0
		}
		// let gdata = fld(rawData, opts)
		let data = formLineData(rawData, opts)

		return (
			<div className="MonthlyGraph">
				{/* Code for chart.js 2 */}
				{/*
				<Line
					redraw
					data={this.state.chartData}
					options={this.props.chartOptions}
					height={this.props.height}
				/>
				*/}
				{/* Code for Google Chart API */}
				{/*
				<Chart
					type='LineChart'
					options={{
						hAxis: {minValue: 0},
						vAxis: {
							minValue: 0,
							maxValue: 100,
							baseline: 0,
						},
						curveType: 'none',
						legend: 'none',
						chartArea: {
							left: 50,
							width: '100%',
							top: 20,
							height: '70%',
						},
						animation: {
							startup: true,
							duration: 250,
							easing: 'out',
						},
					}}
					width='100%'
					height={this.props.height}
					rows={gdata}
					columns={[
						{ type: 'string', label: 'Месяц' },
						{ type: 'number', label: 'Обращений' },
						{ type: 'string', role: 'annotation' },
					]}
				/>
				*/}

				<C3Chart
					key={`${this.props.dateFrom}...${this.props.dateTo} ${this.props.detailTopicID} ${this.props.detailSource}`}
					size={{ height: this.props.height }}
					transition={{ duration: 300 }}
					onrendered={() => null}
					{...data}
				/>
			</div>
		);
	}
}


const PATH = ['detail', 'graph']
const SOURCES = [ loadByMonthOrByDay ]

class MonthlyGraphRoot extends Component {
	render() {
		return (
			<div className="MonthlyGraphRoot" key="MonthlyGraphRoot">
				<DataProvider
					dataKey={`${this.props.dateFrom}...${this.props.dateTo} ${this.props.detailTopicID} ${this.props.detailSource}`}
					path={PATH}
					sources={SOURCES}
					component={MonthlyGraph}
					{...this.props}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => ({
	dateFrom: state.getIn(['filterDate', 'from']),
	dateTo: state.getIn(['filterDate', 'to']),

	detailTopicID: state.getIn(['detail', 'filterTopicID']),
	detailSource: state.getIn(['detail', 'filterSource']),

	// dynamicTopicID: state.getIn(['dynamic', 'filterTopicID']),
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MonthlyGraphRoot)
