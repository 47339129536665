import React, { Component } from 'react'
import { connect } from 'react-redux'
import { HorizontalBar } from 'react-chartjs-2'

import DataProvider from './DataProvider'
import { loadBySubcategory } from './asyncAction'
import { formBarData } from './proxy-chartjs.js'


class SubcategoryGraph extends Component {
	render() {
		let rawData = this.props.data.toJS()[0]
		let data = formBarData(rawData, {
			annotate: this.props.annotate,
			mainColor: "248, 151, 26",
			topTen: true,
		})

		return (
			<div className="SubcategoryGraph">
				<HorizontalBar
					data={data}
					options={this.props.chartOptions}
					height={this.props.height}
				/>
			</div>
		);
	}
}


const PATH = ['detail', 'byTopic']
const SOURCES = [ loadBySubcategory ]

class SubcategoryGraphRoot extends Component {
	render() {
		return (
			<div className="SubcategoryGraphRoot" key="SubcategoryGraphRoot">
				<DataProvider
					dataKey={`${this.props.dateFrom}...${this.props.dateTo} ${this.props.detailTopicID} ${this.props.detailSource}`}
					path={PATH}
					sources={SOURCES}
					component={SubcategoryGraph}
					{...this.props}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => ({
	dateFrom: state.getIn(['filterDate', 'from']),
	dateTo: state.getIn(['filterDate', 'to']),

	detailTopicID: state.getIn(['detail', 'filterTopicID']),
	detailSource: state.getIn(['detail', 'filterSource']),

	// dynamicTopicID: state.getIn(['dynamic', 'filterTopicID']),
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SubcategoryGraphRoot)
