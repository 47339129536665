import { createAction } from 'redux-actions'


export const test = createAction('TEST')

export const unsafeDirectSet = createAction('UNSAFE_DIRECT_SET', (key, value) => ({ key, value }))

export const loadStarted = createAction('LOAD_STARTED', (key, dataKey) => ({ key, dataKey }))
export const loadSuccess = createAction('LOAD_SUCCESS', (key, data) => ({ key, data }))
export const loadFailed = createAction('LOAD_FAILED', (key, error) => ({ key, error }))

export const filterDateFromChanged = createAction('FILTER_DATE_FROM_CHANGED', (date) => ({ date }))
export const filterDateToChanged = createAction('FILTER_DATE_TO_CHANGED', (date) => ({ date }))
export const detailFilterTopicIDChanged = createAction('DETAIL_FILTER_TOPIC_ID_CHANGED', (id) => ({ id }))
export const detailFilterSourceChanged = createAction('DETAIL_FILTER_SOURCE_CHANGED', (value) => ({ value }))
export const dynamicFilterTopicIDChanged = createAction('DYNAMIC_FILTER_TOPIC_ID_CHANGED', (id) => ({ id }))
