import { monthMarks } from './utils.js'


/**
 * formLineData prepares data to be consumed by c3js.
 *
 * @param {Object} data - Input data format is:
```
{
 "label": value,
 ...
}
```
 * @param {String} options.name - Visible name of data row
 * @param {Boolean} options.timeseries - Interpret all labels as dates in the given format
 * (like, `YYYY-MM`). Switches to timeseries axis.
 */
export function formLineData(data, options) {
	// labels may be not in ABC order, so we sort them first
	let labels = []
	for (let i of Object.keys(data)) {
		labels.push(i)
	}
	labels = labels.sort()

	// then, insert the data
	let ret = {}
	let dataPoints = []
	for (let key of labels) {
		dataPoints.push(data[key])
	}
	ret.data = {
		columns: [
			[options.name].concat(dataPoints),
		],
		labels: true,
		type: 'spline',
	}
	ret.axis = {
		x: {
			tick: {
			},
		},
		y: {
			min: 0,
			padding: {
				bottom: 0,
			},
		}
	}
	ret.legend = {
		show: false,
	}
	ret.grid = {
		y: {
			show: true,
		},
	}
	// ret.color = {
	// 	pattern: ['rgba(237,  26,  59, 1)'],
	// }
	if (options.timeseries) {
		ret.axis.x.type = 'timeseries'
		ret.data.x = 'x'
		ret.data.xFormat = options.timeseries
		ret.axis.x.tick.format = options.timeseries
		ret.data.columns.push(['x'].concat(labels))
	} else {
		ret.axis.x.type = 'category'
		ret.axis.x.categories = labels
		ret.axis.x.tick.rotate = 90
	}
	return ret
}
