import { monthMarks } from './utils.js';


// valid values for overlay are: anything falsy, 'value', 'percent'
// valid values for orientation are: anything falsy (for vertical), 'horizontal'
export const getChartOptions = function(overlay, customFontSize, orientation) {
	let nameAxis = {
		gridLines: {
			display: false,
		},
		ticks: {
			beginAtZero: true,
			fontSize: (customFontSize ? customFontSize : 12),
		},
	};

	let valueAxis = {
		gridLines: {
			borderDash: [6, 4],
		},
		ticks: {
			beginAtZero: true,
			//fixedStepSize: 100,
		},
	};

	let scaleconf = {
		yAxes: [valueAxis],
		xAxes: [nameAxis],
	};
	if (orientation === 'horizontal') {
		scaleconf = {
			yAxes: [nameAxis],
			xAxes: [valueAxis],
		};
	}

	return {
		responsive: true,
		maintainAspectRatio: false,
		legend: {
			display: false,
		},
		scales: scaleconf,
		// Show values over points
		// Source: http://stackoverflow.com/questions/31631354/how-to-display-data-values-on-chart-js
		animation: {
			duration: 500,
			easing: "easeOutQuart",
			onProgress: function (e) {
				if (!overlay) return;

				var ctx = this.chart.ctx;
				//ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
				ctx.textAlign = 'center';
				ctx.textBaseline = 'bottom';

				this.data.datasets.forEach(function (dataset) {
					let sum = 0;
					for (let i = 0; i < dataset.data.length; i++) {
						sum += dataset.data[i];
					}

					for (var i = 0; i < dataset.data.length; i++) {
						var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
						//let x_max = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._xScale.maxWidth;
						let y_max = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._yScale.maxHeight;

						let percent = Math.round(dataset.data[i] * 100 / sum);

						ctx.fillStyle = '#555';
						// I made a nifty animation, but it doesn't work well with tooltips.
						/*
						let col = 15 - Math.round(e.animationObject.currentStep * 9 / e.animationObject.numSteps);
						if (col === 15) col = 'f';
						if (col === 14) col = 'e';
						if (col === 13) col = 'd';
						if (col === 12) col = 'c';
						if (col === 11) col = 'b';
						if (col === 10) col = 'a';
						ctx.fillStyle = '#'+col+''+col+''+col;
						*/

						let x_pos = model.x;
						let y_pos = model.y;

						if (orientation === 'horizontal') {
							x_pos = model.x + 20;
							y_pos = model.y + 7; // magic
							// Ignore border collisions 'cause we're badass
						} else {
							y_pos = model.y - 5;
							// Make sure data value does not get overflown and hidden
							// when the bar's value is too close to max value of scale
							// Note: The y value is reverse, it counts from top down
							if ((y_max - model.y) / y_max >= 0.93) y_pos = model.y + 20;
						}

						let text = (overlay === 'percent' ? `${percent}%` : `${dataset.data[i]}`);
						ctx.fillText(text, x_pos, y_pos);
					}
				});
			},
			// onComplete: anim,
		},
	};
};


// formBarData prepares data to be consumed by chart.js.
// Input data format is:
// {
//   "label": value,
//   ...
// }
// Options are:
// - annotate: object [key]replacement
// - mainColor: string rgb "1,2,3"
// - topTen: boolean
// - splitLines: boolean
// - from, to: Date - If specified, add all months from this date span as "YYYY-MM" with 0 as value.
export function formBarData(data, options) {
	// add missing months if caller expects this
	if ((options) && (options.from) && (options.to)) {
		let months = monthMarks(options.from, options.to);
		for (let i in months) { if (months.hasOwnProperty(i)){
			let month = months[i];
			data[month] = data[month] || 0;
		}}
	}

	let labels = [];
	let row = [];
	// process data
	if (options.topTen === true) {
		// sort and build top-10
		let table = [];
		for (let i in data) { if (data.hasOwnProperty(i)) {
			table.push({
				label: i,
				value: data[i],
			});
		}}
		table.sort(function(a, b){
			if (a.value < b.value) return 1;
			if (a.value > b.value) return -1;
			return 0;

			// Oneline version that works only in Firefox:
			// return a.value < b.value;
		});
		table = table.slice(0, 10);
		for (let i in table) { if (table.hasOwnProperty(i)) {
			labels.push(table[i].label);
			row.push(table[i].value);
		}}
	} else {
		if ((options) && (options.from) && (options.to)) {
			// if data is dated, we sort labels in ABC order
			for (let i in data) { if (data.hasOwnProperty(i)) {
				labels.push(i);
			}}
			labels = labels.sort();

			// then, insert the data
			for (let i in labels) { if (labels.hasOwnProperty(i)) {
				row.push(data[labels[i]]);
			}}
		} else {
			// just push data in original order
			for (let i in data) { if (data.hasOwnProperty(i)) {
				labels.push(i);
				row.push(data[i]);
			}}
		}
	}

	// annotate labels
	if (options.annotate != null) {
		for (let i in labels) { if (labels.hasOwnProperty(i)) {
			if (options.annotate[labels[i]] != null) {
				labels[i] = options.annotate[labels[i]];
				labels[i] = labels[i].replace(/^\d+[.]\s*/, ''); // strip leading numbers
			}
		}}
	}

	// annotate labels again
	if (options.reannotate != null) {
		for (let i in labels) { if (labels.hasOwnProperty(i)) {
			if (options.reannotate[labels[i]] != null) {
				labels[i] = options.reannotate[labels[i]];
			}
		}}
	}

	// split labels into lines
	if (options.splitLines === true) {
		for (let i in labels) { if (labels.hasOwnProperty(i)) {
			labels[i] = labels[i].split(/\s+/g);
		}}
	}

	// choose colors
	let bgColor = [
		'rgba(237,  26,  59, 0.5)',
		'rgba(247, 151,  28, 0.5)',
		'rgba(  3,  77, 162, 0.5)',
		'rgba( 91, 155, 213, 0.5)',
		'rgba(153, 102, 255, 0.5)',
		'rgba(255, 159,  64, 0.5)'
	];
	let bColor = [
		'rgba(237,  26,  59, 1)',
		'rgba(247, 151,  28, 1)',
		'rgba(  3,  77, 162, 1)',
		'rgba( 91, 155, 213, 1)',
		'rgba(153, 102, 255, 1)',
		'rgba(255, 159,  64, 1)'
	];
	if (options.mainColor != null) {
		bgColor = `rgba(${options.mainColor}, 0.5)`;
		bColor = `rgba(${options.mainColor}, 1)`;
	}

	return {
		labels: labels,
		datasets: [
			{
				label: 'Обращений',
				backgroundColor: bgColor,
				borderColor: bColor,
				borderWidth: 1,
				data: row,
			}
		],
	}
}


// formLineData prepares data to be consumed by chart.js.
// Input data format is:
// {
//   "label": value,
//   ...
// }
// Options are:
// - from, to [Date] If specified, add all months from this date span with 0 as value.
export function formLineData(data, options) {
	// add missing months if caller expects this
	if ((options) && (options.from) && (options.to)) {
		let months = monthMarks(options.from, options.to);
		for (let i in months) { if (months.hasOwnProperty(i)){
			let month = months[i];
			data[month] = data[month] || 0;
		}}
	}

	// labels may be not in ABC order, so we sort them first
	let labels = [];
	for (let i in data) {
		if (data.hasOwnProperty(i)) {
			labels.push(i);
		}
	}

	// then, insert the data
	labels = labels.sort();
	let row = [];
	for (let i in labels) {
		if (data.hasOwnProperty(labels[i])) {
			row.push(data[labels[i]]);
		}
	}

	return {
		labels: labels,
		datasets: [{
			label: "Обращений",
			backgroundColor: "rgba(151,187,205,0.2)",
			borderWidth: 2,
			borderColor: "rgba(151,187,205,1)",
			pointBackgroundColor: "rgba(151,187,205,1)",
			pointBorderColor: "#fff",
			pointHoverBackgroundColor: "#fff",
			pointHoverBorderColor: "rgba(151,187,205,1)",
			data: row,
		}],
	}
}
