import { API_PREFIX } from './config'
import { withinOneMonth } from './utils'
// import { selectShippingShopIds, selectShippingRangeMonths, selectShippingShops } from './selector'


const handleResponse = (response, json = true) => {
	if (response.ok) {
		if (json) {
			return response.json()
		} else {
			return response
		}
	} else {
		throw response
	}
}

// function getCookie(name) {
// 	var cookieValue = null;
// 	if (document.cookie && document.cookie !== '') {
// 		var cookies = document.cookie.split(';');
// 		for (var i = 0; i < cookies.length; i++) {
// 			var cookie = cookies[i].trim();
// 			// Does this cookie string begin with the name we want?
// 			if (cookie.substring(0, name.length + 1) === (name + '=')) {
// 				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
// 				break;
// 			}
// 		}
// 	}
// 	return cookieValue;
// }

// const getCSRFToken = () => {
// 	return getCookie('csrftoken')
// }


export const loadSources = () => {
	const url = `${API_PREFIX}/sources`
	return fetch(url, {
		method: 'GET',
		credentials: 'include',
	}).then((response) => {
		return handleResponse(response, true)
	})
}


export const loadTopics = () => {
	const url = `${API_PREFIX}/topics`
	return fetch(url, {
		method: 'GET',
		credentials: 'include',
	}).then((response) => {
		return handleResponse(response, true)
	})
}


export const loadByCategory = (dispatch, getState) => {
	let state = getState()
	let from = state.getIn(['filterDate', 'from'])
	let to = state.getIn(['filterDate', 'to'])

	const url = `${API_PREFIX}/stats/by-topic`
	return fetch(url, {
		method: 'POST',
		credentials: 'include',
		//mode: 'cors',
		body: JSON.stringify({
			merge_children: true,
			extended_limiter: {
				raw_limiter: {
					time_start: new Date(from),
					time_end: new Date(to),
				}
			},
		}),
	}).then((response) => {
		return handleResponse(response, true)
	})
}


export const loadBySource = (dispatch, getState) => {
	let state = getState()
	let from = state.getIn(['filterDate', 'from'])
	let to = state.getIn(['filterDate', 'to'])

	const url = `${API_PREFIX}/stats/by-source`
	return fetch(url, {
		method: 'POST',
		credentials: 'include',
		//mode: 'cors',
		body: JSON.stringify({
			raw_limiter: {
				time_start: new Date(from),
				time_end: new Date(to),
			},
		}),
	}).then((response) => {
		return handleResponse(response, true)
	})
}


export const loadTotalCount = (dispatch, getState) => {
	let state = getState()
	let from = state.getIn(['filterDate', 'from'])
	let to = state.getIn(['filterDate', 'to'])

	const url = `${API_PREFIX}/stats/total`
	return fetch(url, {
		method: 'POST',
		credentials: 'include',
		//mode: 'cors',
		body: JSON.stringify({
			raw_limiter: {
				time_start: new Date(from),
				time_end: new Date(to),
			},
		}),
	}).then((response) => {
		return handleResponse(response, true)
	})
}


export const loadTeamTransferCount = (team_name) => (dispatch, getState) => {
	let state = getState()
	let from = state.getIn(['filterDate', 'from'])
	let to = state.getIn(['filterDate', 'to'])

	const url = `${API_PREFIX}/thread-by-team/count-unique-tickets`
	return fetch(url, {
		method: 'POST',
		credentials: 'include',
		//mode: 'cors',
		body: JSON.stringify({
			ticket_created_from: new Date(from),
			ticket_created_to: new Date(to),
			team_name: team_name,
		}),
	}).then((response) => {
		return handleResponse(response, true)
	})
}


export const loadByMonthOrByDay = (dispatch, getState) => {
	let state = getState()
	let from = state.getIn(['filterDate', 'from'])
	let to = state.getIn(['filterDate', 'to'])
	let topicID = state.getIn(['detail', 'filterTopicID'])
	let source = state.getIn(['detail', 'filterSource'])

	let url = API_PREFIX
	if (withinOneMonth(from, to)) {
		url += `/stats/by-day`
	} else {
		url += `/stats/by-month`
	}

	return fetch(url, {
		method: 'POST',
		credentials: 'include',
		//mode: 'cors',
		body: JSON.stringify({
			raw_limiter: {
				time_start: new Date(from),
				time_end: new Date(to),
				source: (source === '' ? null : source),
			},
			//topic_id: props.topic,
			//topic_include_children: true,
			topic_id_with_children: topicID,
		}),
	}).then((response) => {
		return handleResponse(response, true)
	})
}


export const loadBySubcategory = (dispatch, getState) => {
	let state = getState()
	let from = state.getIn(['filterDate', 'from'])
	let to = state.getIn(['filterDate', 'to'])
	let topicID = state.getIn(['detail', 'filterTopicID'])
	let source = state.getIn(['detail', 'filterSource'])

	const url = `${API_PREFIX}/stats/by-topic`
	return fetch(url, {
		method: 'POST',
		credentials: 'include',
		//mode: 'cors',
		body: JSON.stringify({
			merge_children: false,
			extended_limiter: {
				topic_id_with_children: topicID,
				raw_limiter: {
					time_start: new Date(from),
					time_end: new Date(to),
					// topic_id: props.topic,
					// topic_include_children: true,
					source: (source === '' ? null : source),
				},
			},
		}),
	}).then((response) => {
		return handleResponse(response, true)
	})
}


export const loadByCar = (dispatch, getState) => {
	let state = getState()
	let from = state.getIn(['filterDate', 'from'])
	let to = state.getIn(['filterDate', 'to'])
	let topicID = state.getIn(['detail', 'filterTopicID'])
	let source = state.getIn(['detail', 'filterSource'])

	const url = `${API_PREFIX}/stats/by-car`
	return fetch(url, {
		method: 'POST',
		credentials: 'include',
		//mode: 'cors',
		body: JSON.stringify({
			topic_id_with_children: topicID,
			raw_limiter: {
				time_start: new Date(from),
				time_end: new Date(to),
				// topic_id: topic,
				// topic_include_children: true,
				source: (source === '' ? null : source),
			},
		}),
	}).then((response) => {
		return handleResponse(response, true)
	})
}


export const loadDynamic = (dispatch, getState) => {
	let state = getState()
	let from = state.getIn(['filterDate', 'from'])
	let to = state.getIn(['filterDate', 'to'])
	let topicID = state.getIn(['dynamic', 'filterTopicID'])

	let url = `${API_PREFIX}/stats/by-month`
	return fetch(url, {
		method: 'POST',
		credentials: 'include',
		//mode: 'cors',
		body: JSON.stringify({
			topic_id_with_children: topicID,
			raw_limiter: {
				time_start: new Date(from),
				time_end: new Date(to),
				// topic_id: props.topic,
				// topic_include_children: true,
			},
		}),
	}).then((response) => {
		return handleResponse(response, true)
	})
}


// export const loadBubblechartData = (dispatch, getState) => {
// 	let state = getState()

// 	let shops = selectShippingShopIds(state)
// 	let range = state.getIn(['shipping', 'date'])

// 	let payload = shops.map((id) => ({
// 		months: range.get('months').toJS(),
// 		year: range.get('year'),
// 		shop_id: id,
// 	}))

// 	const url = `${API_PREFIX}/shipping/result-index/query_mass/`
// 	return fetch(url, {
// 		method: 'POST',
// 		credentials: 'include',
// 		body: JSON.stringify(payload),
// 		headers: {
// 			'Content-Type': 'application/json',
// 			'X-CSRFToken': getCSRFToken(),
// 		},
// 	}).then((response) => {
// 		return handleResponse(response, true)
// 	})
// }
